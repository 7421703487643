import styled from "styled-components";

const Container = styled.div`
  margin-top: 100px;
`;

const Post = styled.div`
  margin: 40px 0;
  a {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 5px;

    h3 {
      border-bottom: 1px solid #7e65e0;
      display: inline;
      margin-bottom: 10px;
      transition: .15s ease-out border;
    }

    &:hover {
      h3 {
        border-bottom: 1px solid transparent;
      }
    }   
  }

  p {
    margin-top: 0;

    span {
      font-style: italic;
    }
  }
`

const FeaturedPosts = (props) => {

  function getFormattedDate(dateStr) {
    const dateObj = new Date(dateStr.replace(/-/g, "/")); // replace '-' with '/' in date to keep Safari happy
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: "numeric",
      month: "short",
      day: "numeric"
    }).format(dateObj);    

    return <span>{formattedDate}</span>
  }
  
  return (
    <Container>

      <h2>Latest Medium Posts</h2>

      {props.posts.map((post, index) => {
        return (

          <Post key={index}>
            <a href={post.link} target="_blank" rel="noreferrer">
              <h3>{post.title}</h3>
            </a>
            
            <p>{getFormattedDate(post.pubDate)}</p>
          </Post>
        )
      })}
    </Container>
  );
};

export default FeaturedPosts;
