import styled from "styled-components";

const Container = styled.div`
  margin-top: 40px;
`;

const Card = styled.div`
  a {
    display: block;
    background: #222451;
    color: white;
    padding: 30px;
    margin: 20px 0;
    text-decoration: none;
    color: white;
    transition: .17s ease-in-out all;

    &:hover {
      transform: scale(1.05);
    }

    h3 {
      margin-top: 0;
      margin-bottom: 10px;
    }

    p {
      margin: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        margin-right: 20px;
        font-size: 15px;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
      }
    }
  }
`

const FeaturedCards = (props) => {

  function renderTags(cardData) {
    return cardData.tags.map((tag, i) => {
      return <li key={i}>{tag}</li>
    });
  }

  return (
    <Container>
      <h2>Featured Projects</h2>
      {props.cards.map((card, index) => {
        return (

          <Card key={index}>
            <a href={card.url} target="_blank" rel="noreferrer">
              <h3>{card.title}</h3>
              <p>{card.description}</p>
              <ul className="tags">
                {renderTags(card)}
              </ul>
            </a>

          </Card>
        )
      })}
    </Container>
  );
};

export default FeaturedCards;
