import styled from "styled-components";

const Container = styled.div`
  margin: 80px 0 40px;
  p {
    font-size: 16px;

    a {
      text-decoration: none;
      border-bottom: 1px solid #7e65e0;
      transition: .15s ease-out border;

      &:hover {
        border-bottom-color: transparent;
      }
    }
  }
`;

const Footer = () => {
  return (
    <Container>
      <p>Made with ❤️ in Houston, TX.</p>
    </Container>
  )
}

export default Footer;