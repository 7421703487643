import './App.scss';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Particles from 'react-particles-js';
import Intro from './components/Intro';
import FeaturedCards from './components/FeaturedCards';
import FeaturedPosts from './components/FeaturedPosts';
import Footer from './components/Footer';
import projectsData from './json/data.json';
import particlesData from './json/particles.json'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  padding: 0 25px;
  // z-index: 2;
`

const LeftCol = styled.div`
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 991px) {
    flex: 1;
    // max-width: 460px;
    margin-right: 10%;
  }
`

const RightCol = styled.div`
  width: 100%;

  @media (min-width: 991px) {
    flex: 1;
  }
`

function App() {

  const [mediumPosts, setMediumPosts] = useState([]);
  const rss2jsonBase = 'https://api.rss2json.com/v1/api.json';
  const mediumProfileUrl = 'https://medium.com/feed/@johnludena';

  useEffect(() => {

    const url = `${rss2jsonBase}?rss_url=${mediumProfileUrl}`;

    fetch(url)
      .then(response => response.json())
      .then(data => setMediumPosts(data.items))
      .catch(error => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <Particles
        className="stars-wrapper" 
        params={particlesData}
          style={{
            width: '100%',
          }}
        /> 
      <Container>
        <LeftCol>
          <Intro />
        </LeftCol>

        <RightCol>
          <FeaturedCards cards={projectsData.cards} />
          <FeaturedPosts posts={mediumPosts} />
          <Footer />
          
        </RightCol>
      </Container>  
      
    </React.Fragment> 
  );
}

export default App;
