import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const githubProfileImgUrl = "https://avatars.githubusercontent.com/johnludena"

const Container = styled.div`
  @media (min-width: 991px) {
    position: sticky;
    top: 30px;
    left: 0;
  }
  
  > p {
    font-size: 24px;

    > a {
      border-bottom: 1px solid #7e65e0;
      transition: .15s ease-out border;
      text-decoration: none;

      &:hover {
        border-bottom-color: transparent;
      }
    }

    strong {
      color: #eea553;
    }
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  img#profile-picture {
    max-width: 60px;
    border-radius: 100%;
    align-self: center;

    @media (min-width: 768px) {
      align-self: start;
    }
  }

  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    @media (min-width: 768px) {
      margin-top: 0;
      margin-left: 28px;
    }

    > a {
      margin-right: 15px;
      text-decoration: none;
      display: flex;

      @media (min-width: 768px) {
        margin-right: 20px;
      }

      span {
        border-bottom: 1px solid #7e65e0;
        transition: .15s ease-out border;

        @media (max-width: 767px) {
          font-size: 16px;
        }
      }

      &:hover {
        span {
          border-bottom: 1px solid transparent;
        }
      }

      svg {
        margin-right: 6px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const Intro = () => {
  return (
    <Container>
      <h1>Hi there, I'm John. 🌝</h1>
      <p>I’m a Software Engineer with <strong>over 10 years of professional experience</strong> building websites for a wide-range of companies: from brand-new startups to publicly-traded companies.</p>
      
      <p>I am obsessed with building world-class user-interfaces, learning new things, and becoming 1% better every day.</p>

      <p><a href="mailto:johnludena@gmail.com">Want to say hi?</a></p>

      <ProfileContainer>
        <img id="profile-picture" src={githubProfileImgUrl} alt="John Ludena" />
        
        <div className="social-links">
          <a href="http://www.github.com/johnludena" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faGithub} /> <span>Github</span>
          </a>

          <a href="http://www.linkedin.com/in/john-ludena" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faLinkedin} /> <span>LinkedIn</span>
          </a>

        </div>
      </ProfileContainer>

    </Container>
  )
}

export default Intro;